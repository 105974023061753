
.triangle:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%; 
  height: 300px;
  background: white;
  clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
  z-index: 110;
}

.about {
  max-width: 1280px;
  width: 100%; 
  display: block;
  margin: 0 auto;
  padding: 0 8%;
  z-index: 100;
  margin-top: 7rem;
  color: black;
}

.header {
  margin: auto;
  margin-bottom: 1rem;
  font-size: 3rem;
  z-index: 40;
  color: white;
  font-weight: bold;
}

.headerTwo {
  z-index: 40;
  color: white;
  font-weight: bold;
  font-size: 2rem;
}

.text-container {
  z-index: 50;
  width: 75%;
  text-align: start;
  font-size: 0.2rem;
  padding: 2rem;
  font-size: 1rem;
  color: white;
}

.education {
  margin-top: 4rem;
  font-size: 1.6rem;
}

.textp {
  font-size: 1.1rem;
}

.cv-button {
  margin: 3rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 4rem;
  width: 45%;
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: white;
  border: solid rgb(253, 146, 146);;
  background-color: white;
  flex-direction: row;
  box-shadow: 0.1rem 0.1rem 0.3rem #777;
  transition: box-shadow 0.4s;
}

.cv-button:hover {
  background-image: linear-gradient(to right, rgba(253, 146, 146), rgba(234, 93, 11, 0.75), rgb(65, 6, 105));
  color: white;
  border: black solid;
  box-shadow: 0.3rem 0.3rem 0.5rem #777;
}

.cv-button img {
  margin: 0 0.4rem;
  height: 2rem;
  width: 2rem;
}

.container-details {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  font-size: 1.5rem;
  color: white;
}

.containerL {
  width: 80%;
  background-color: white;
  box-shadow: 0.2rem 0.2rem 0.4rem #777;
}

.containerColumns {
  display: flex;
  flex-direction: row;
}

.sectiontwo img {
  border: white solid 1rem;
  width: 17rem;
  height: 23rem;
  margin: 1.4rem;
  transition-duration: 3s;
  box-shadow: 0.3rem 0.3rem 0.5rem #777;
}

.sectiontwo img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  z-index: 0;
}

.divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(253, 146, 146), rgba(234, 93, 11, 0.75), rgb(131, 9, 212));
  margin-top: 2rem;
  margin-bottom: 2rem;
}


@media (max-width: 1000px) {
  .header {
    display: flex;
    justify-content: center;
  }

  .cv-button{
    width: 80%;
  }

  .containerColumns {
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
  }

  .text-container {
    text-align: center;
    margin: auto;
  }
  
  .container-details {
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
  }

  .sectiontwo {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }

  .containerLanguage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .one-language {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}

@media (min-width: 1600px) {
.sectiontwo img {
  animation: images 7s ease-out;

}

@keyframes images {
from {
  transform: translateX(150%);
  opacity: 0;
}

to {
  transform: translateX(0%);
  opacity: 1;
}
}
}