.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  height: 5rem;
  background-color:  #292421;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  z-index: 100;
}

.nav a {
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
  text-decoration: underline 0.15rem rgba(127, 5, 95, 0);
  transition: text-decoration-color;
  margin: 0 1.75rem;
}

.nav a:hover {
  text-transform: uppercase;
  background-image: linear-gradient(to right, rgba(253, 146, 146), rgba(234, 93, 11, 0.75), rgb(131, 9, 212));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; 
  -webkit-text-fill-color: transparent; 
  cursor: pointer;
  font-size: 2rem;
}

@media (max-width: 720px) {
  .nav{
    justify-content: space-around;
    margin: 0;
  }
  .nav a{
    font-size: 1rem;
    margin: 0.5rem;
  }
}
