@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
  
 
}

html {
  font-family: "Noto Sans JP", sans-serif;
  scroll-behavior: smooth;
  
}

body {
  margin: 0;
  background-color: black;
}

/*#f8edeb background-color: #F5F5F5;*/


.About_triangle__3yLBe:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%; 
  height: 300px;
  background: white;
  -webkit-clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
          clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
  z-index: 110;
}

.About_about__3gFWk {
  max-width: 1280px;
  width: 100%; 
  display: block;
  margin: 0 auto;
  padding: 0 8%;
  z-index: 100;
  margin-top: 7rem;
  color: black;
}

.About_header__2-31z {
  margin: auto;
  margin-bottom: 1rem;
  font-size: 3rem;
  z-index: 40;
  color: white;
  font-weight: bold;
}

.About_headerTwo__P22ht {
  z-index: 40;
  color: white;
  font-weight: bold;
  font-size: 2rem;
}

.About_text-container__37A3B {
  z-index: 50;
  width: 75%;
  text-align: start;
  font-size: 0.2rem;
  padding: 2rem;
  font-size: 1rem;
  color: white;
}

.About_education__M33v6 {
  margin-top: 4rem;
  font-size: 1.6rem;
}

.About_textp__11zT9 {
  font-size: 1.1rem;
}

.About_cv-button__2kufw {
  margin: 3rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 4rem;
  width: 45%;
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: white;
  border: solid rgb(253, 146, 146);;
  background-color: white;
  flex-direction: row;
  box-shadow: 0.1rem 0.1rem 0.3rem #777;
  transition: box-shadow 0.4s;
}

.About_cv-button__2kufw:hover {
  background-image: linear-gradient(to right, rgba(253, 146, 146), rgba(234, 93, 11, 0.75), rgb(65, 6, 105));
  color: white;
  border: black solid;
  box-shadow: 0.3rem 0.3rem 0.5rem #777;
}

.About_cv-button__2kufw img {
  margin: 0 0.4rem;
  height: 2rem;
  width: 2rem;
}

.About_container-details__3V-Ua {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  font-size: 1.5rem;
  color: white;
}

.About_containerL__3EHHt {
  width: 80%;
  background-color: white;
  box-shadow: 0.2rem 0.2rem 0.4rem #777;
}

.About_containerColumns__14gil {
  display: flex;
  flex-direction: row;
}

.About_sectiontwo__30eVk img {
  border: white solid 1rem;
  width: 17rem;
  height: 23rem;
  margin: 1.4rem;
  transition-duration: 3s;
  box-shadow: 0.3rem 0.3rem 0.5rem #777;
}

.About_sectiontwo__30eVk img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  z-index: 0;
}

.About_divider__2_Em3 {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(253, 146, 146), rgba(234, 93, 11, 0.75), rgb(131, 9, 212));
  margin-top: 2rem;
  margin-bottom: 2rem;
}


@media (max-width: 1000px) {
  .About_header__2-31z {
    display: flex;
    justify-content: center;
  }

  .About_cv-button__2kufw{
    width: 80%;
  }

  .About_containerColumns__14gil {
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
  }

  .About_text-container__37A3B {
    text-align: center;
    margin: auto;
  }
  
  .About_container-details__3V-Ua {
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
  }

  .About_sectiontwo__30eVk {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }

  .About_containerLanguage__10e8M {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .About_one-language__3WQ5L {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}

@media (min-width: 1600px) {
.About_sectiontwo__30eVk img {
  -webkit-animation: About_images__PkeT- 7s ease-out;
          animation: About_images__PkeT- 7s ease-out;

}

@-webkit-keyframes About_images__PkeT- {
from {
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
  opacity: 0;
}

to {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}
}

@keyframes About_images__PkeT- {
from {
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
  opacity: 0;
}

to {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}
}
}

.Experience_triangle__1IBuS:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%; 
  height: 300px;
  background: rgb(241, 100, 218);
  -webkit-clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
          clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
  z-index: 110;
}

.Experience_experience__3qzBU {
    max-width: 1280px;
    width: 100%; 
    display: block;
    margin: 0 auto;
    padding: 0 8%;
    z-index: 100;
    color: white;
  }

  .Experience_header__3gNFM {
    margin: auto;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    z-index: 40;
    color: white;
    font-weight: 600;
  }
  
.Experience_education__VrjTI {
  margin-top: 4rem;
  font-size: 2.5rem;
  color: white;
  font-weight: 600;
}

.Experience_subHeader__1uIuB {
  font-size: 1.4rem;
}

.Experience_textp__d2fMb {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.Experience_divider__35o-x {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(253, 146, 146), rgba(234, 93, 11, 0.75),  rgb(131, 9, 212));
  margin-top: 2rem;
  margin-bottom: 2rem;
}


.Experience_containerColumns__1x_UI {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .Experience_containerColumns__1x_UI {
    flex-direction: column;
  }
}

.Footer_footer__2_9sI {
  background-color: #292421;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  margin-top: 3rem;
}

.Navbar_nav__1wRSL {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  height: 5rem;
  background-color:  #292421;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  z-index: 100;
}

.Navbar_nav__1wRSL a {
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
  -webkit-text-decoration: underline 0.15rem rgba(127, 5, 95, 0);
          text-decoration: underline 0.15rem rgba(127, 5, 95, 0);
  transition: -webkit-text-decoration-color;
  transition: text-decoration-color;
  transition: text-decoration-color, -webkit-text-decoration-color;
  margin: 0 1.75rem;
}

.Navbar_nav__1wRSL a:hover {
  text-transform: uppercase;
  background-image: linear-gradient(to right, rgba(253, 146, 146), rgba(234, 93, 11, 0.75), rgb(131, 9, 212));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; 
  -webkit-text-fill-color: transparent; 
  cursor: pointer;
  font-size: 2rem;
}

@media (max-width: 720px) {
  .Navbar_nav__1wRSL{
    justify-content: space-around;
    margin: 0;
  }
  .Navbar_nav__1wRSL a{
    font-size: 1rem;
    margin: 0.5rem;
  }
}



.Projects_container__ICQ7x {
  width: 100%; 
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 8%;
  margin-top: 7rem;
  background-color: white;
  z-index: 80;
}

.Projects_header__Yh8V6 {
  display: flex;
  padding-top: 3rem;
  justify-content: center;
  font-size: 4rem;
  font-weight: bold;
  color: black;
}

.Projects_wrapper__31e8P {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Projects_project__2GNh4 {
  display: flex;
  margin: 4rem 2.8rem;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(253, 146, 146);;
  align-items: center;
  box-shadow: 0.3rem 0.3rem 0.5rem #777;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.5s;
}

.Projects_project__2GNh4:hover {
  box-shadow: 1rem 1rem 1rem #777;
}

.Projects_text__3Bb48 {
  text-align: center;
  letter-spacing: 0.1rem;
  position: absolute;
  top: -20rem;
  z-index: 90;
  transition: top 0.3s;
}

.Projects_project__2GNh4:hover .Projects_text__3Bb48 {
  top: 5rem;
  transition: top 0.3s 0.3s;
}

.Projects_text__3Bb48 h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: black;
  text-transform: capitalize;
}

.Projects_text__3Bb48 h4 {
  font-size: 1.5rem;
  color: black;
}

.Projects_images__bjqv3 {
  width: 26rem;
  height: 24rem;
  margin: 1.5rem;
  transition: opacity 0.3s;
}

.Projects_project__2GNh4:hover .Projects_images__bjqv3 {
  opacity: 0.2;
}

.Projects_link__1TRuj {
  text-decoration: none;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  border: 0.2rem solid black;
  color: white;
  padding: 1rem;
  position: absolute;
  bottom: -5rem;
  transition: bottom 0.3s;
  background-color: black;
  border-radius: 0.5rem;
}

.Projects_project__2GNh4:hover .Projects_link__1TRuj {
  bottom: 5rem;
  transition: bottom 0.3s 0.3s;
}



@media (max-width: 720px) {

  .Projects_container__ICQ7x {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Projects_container__ICQ7x {
    padding: 0;
  }

  .Projects_wrapper__31e8P{
    margin: 0;
  }

}

@media (max-width: 600px) {

  .Projects_container__ICQ7x h2{
    font-size: 3rem;
  }

  .Projects_project__2GNh4 img {
    width: 16rem;
    height: 14rem;
  }
  
  .Projects_text__3Bb48 h3 {
    font-size: 1.4rem;
    color: black;
  }

  .Projects_text__3Bb48 h4 {
    font-size: 0.8rem;
    color: black;
    margin: 0.5rem 0;
  }

  .Projects_text__3Bb48{
    margin-top: -2rem;
  }

  .Projects_project__2GNh4 a{
    margin: -2rem;
  }

  .Projects_link__1TRuj{
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.5rem;

  }
  .Projects_project__2GNh4{
    margin: 4rem 0.4rem;
  }

  }
.Header_header__3MZtQ {
  max-width: 100%; 
  display: flex;
  left: 0;
  top: 0;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}

.Header_rectangle__Ymdee {
  width: 15rem;
  height: 20rem;
  right: 40%;
  top: -13%;
  background-color: black;
  position: absolute;
  z-index: 70;
}

.Header_title__VkboX{
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1rem;
}

section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  background: #fff;
  -webkit-clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 0%);
          clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 0%);
}

.Header_triangle__rVwvR:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%; 
  height: 300px;
  background: rgb(253, 146, 146);
  -webkit-clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
          clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
  z-index: -10;
}

.Header_container__2InBp {
  align-items: center;
  width: 50%;
  margin-top: 5rem;
  margin-left: 13rem;
  z-index: 70;
}

.Header_font__1y2e- {
  color: white;
  font-size: 2rem;
  -webkit-animation: Header_slidein__146pP 2s ease;
          animation: Header_slidein__146pP 2s ease;
}

.Header_photo-wrapper__1-Uyq {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.Header_icon__22t5b {
  display: flex;
  cursor: pointer;
}

.Header_icon__22t5b a:hover img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5); 
}

.Header_icon__22t5b img {
  z-index: 40;
  margin: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}

.Header_photome__1211t {
  z-index: 70;
}

.Header_photome__1211t img {
  border: white solid 0.2rem;
  width: 16rem;
  height: 21rem;
}

@-webkit-keyframes Header_slidein__146pP {
  from {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes Header_slidein__146pP {
  from {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@media (max-width: 900px){
  .Header_font__1y2e- {
    color: black;
  }
}

@media (max-height: 960px){
  header{
    height: 130vh;
  }
}

@media (max-height: 720px){
  header{
    height: 170vh;
  }

}

@media (max-width: 860px) {
  .Header_container__2InBp {
    margin: auto;
    width: 50%;
  }
  .Header_font__1y2e- {
    font-size: 1.5rem;
    -webkit-animation: none;
            animation: none;
  }

  .Header_header__3MZtQ {
    flex-direction: row;
    align-items: center;
  }

  .Header_icon__22t5b {
    justify-content: center;
  }

}

@media (max-width: 860px) {
  .Header_header__3MZtQ {
    flex-direction: column;
  }

  .Header_container__2InBp {
    margin: 0;
  }

  .Header_photo-wrapper__1-Uyq {
    margin: none;
  }

  .Header_font__1y2e- {
    text-align: center;
  }
}


@media (max-width: 1140px) {
  .Header_rectangle__Ymdee {
      left: 30%;
      top: 13%;
  }

}


@media (max-width: 720px) {
  .Header_rectangle__Ymdee {
      width: 0rem;
      height: 0rem
  }

}
