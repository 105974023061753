.header {
  max-width: 100%; 
  display: flex;
  left: 0;
  top: 0;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}

.rectangle {
  width: 15rem;
  height: 20rem;
  right: 40%;
  top: -13%;
  background-color: black;
  position: absolute;
  z-index: 70;
}

.title{
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1rem;
}

section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  background: #fff;
  clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 0%);
}

.triangle:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%; 
  height: 300px;
  background: rgb(253, 146, 146);
  clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
  z-index: -10;
}

.container {
  align-items: center;
  width: 50%;
  margin-top: 5rem;
  margin-left: 13rem;
  z-index: 70;
}

.font {
  color: white;
  font-size: 2rem;
  animation: slidein 2s ease;
}

.photo-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.icon {
  display: flex;
  cursor: pointer;
}

.icon a:hover img {
  transform: scale(1.5); 
}

.icon img {
  z-index: 40;
  margin: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}

.photome {
  z-index: 70;
}

.photome img {
  border: white solid 0.2rem;
  width: 16rem;
  height: 21rem;
}

@keyframes slidein {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0%);
  }
}

@media (max-width: 900px){
  .font {
    color: black;
  }
}

@media (max-height: 960px){
  header{
    height: 130vh;
  }
}

@media (max-height: 720px){
  header{
    height: 170vh;
  }

}

@media (max-width: 860px) {
  .container {
    margin: auto;
    width: 50%;
  }
  .font {
    font-size: 1.5rem;
    animation: none;
  }

  .header {
    flex-direction: row;
    align-items: center;
  }

  .icon {
    justify-content: center;
  }

}

@media (max-width: 860px) {
  .header {
    flex-direction: column;
  }

  .container {
    margin: 0;
  }

  .photo-wrapper {
    margin: none;
  }

  .font {
    text-align: center;
  }
}


@media (max-width: 1140px) {
  .rectangle {
      left: 30%;
      top: 13%;
  }

}


@media (max-width: 720px) {
  .rectangle {
      width: 0rem;
      height: 0rem
  }

}