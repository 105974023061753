
.triangle:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%; 
  height: 300px;
  background: rgb(241, 100, 218);
  clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 50%);
  z-index: 110;
}

.experience {
    max-width: 1280px;
    width: 100%; 
    display: block;
    margin: 0 auto;
    padding: 0 8%;
    z-index: 100;
    color: white;
  }

  .header {
    margin: auto;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    z-index: 40;
    color: white;
    font-weight: 600;
  }
  
.education {
  margin-top: 4rem;
  font-size: 2.5rem;
  color: white;
  font-weight: 600;
}

.subHeader {
  font-size: 1.4rem;
}

.textp {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(253, 146, 146), rgba(234, 93, 11, 0.75),  rgb(131, 9, 212));
  margin-top: 2rem;
  margin-bottom: 2rem;
}


.containerColumns {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .containerColumns {
    flex-direction: column;
  }
}
