@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  
 
}

html {
  font-family: "Noto Sans JP", sans-serif;
  scroll-behavior: smooth;
  
}

body {
  margin: 0;
  background-color: black;
}

/*#f8edeb background-color: #F5F5F5;*/
