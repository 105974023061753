

.container {
  width: 100%; 
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 8%;
  margin-top: 7rem;
  background-color: white;
  z-index: 80;
}

.header {
  display: flex;
  padding-top: 3rem;
  justify-content: center;
  font-size: 4rem;
  font-weight: bold;
  color: black;
}

.wrapper {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.project {
  display: flex;
  margin: 4rem 2.8rem;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(253, 146, 146);;
  align-items: center;
  box-shadow: 0.3rem 0.3rem 0.5rem #777;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.5s;
}

.project:hover {
  box-shadow: 1rem 1rem 1rem #777;
}

.text {
  text-align: center;
  letter-spacing: 0.1rem;
  position: absolute;
  top: -20rem;
  z-index: 90;
  transition: top 0.3s;
}

.project:hover .text {
  top: 5rem;
  transition: top 0.3s 0.3s;
}

.text h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: black;
  text-transform: capitalize;
}

.text h4 {
  font-size: 1.5rem;
  color: black;
}

.images {
  width: 26rem;
  height: 24rem;
  margin: 1.5rem;
  transition: opacity 0.3s;
}

.project:hover .images {
  opacity: 0.2;
}

.link {
  text-decoration: none;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  border: 0.2rem solid black;
  color: white;
  padding: 1rem;
  position: absolute;
  bottom: -5rem;
  transition: bottom 0.3s;
  background-color: black;
  border-radius: 0.5rem;
}

.project:hover .link {
  bottom: 5rem;
  transition: bottom 0.3s 0.3s;
}



@media (max-width: 720px) {

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    padding: 0;
  }

  .wrapper{
    margin: 0;
  }

}

@media (max-width: 600px) {

  .container h2{
    font-size: 3rem;
  }

  .project img {
    width: 16rem;
    height: 14rem;
  }
  
  .text h3 {
    font-size: 1.4rem;
    color: black;
  }

  .text h4 {
    font-size: 0.8rem;
    color: black;
    margin: 0.5rem 0;
  }

  .text{
    margin-top: -2rem;
  }

  .project a{
    margin: -2rem;
  }

  .link{
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.5rem;

  }
  .project{
    margin: 4rem 0.4rem;
  }

  }